import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/app/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/Providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/Theme/ThemeRegistry.tsx");
